<template>
  <div class="login-main">
    <nav-bar></nav-bar>
    <section class="review-order-start order-status-main header-top">
      <div class="container review-main text-capitalize position-relative ">
        <div class="row">
          <div class="text-center col-md-12 p-0 my-2 odr-loadr" v-if="loading">
            <b-spinner class="align-middle"></b-spinner>
          </div>
          <div class="col-md-12 p-0">
            <div class="center-card | bg-transparant">
              <div class=" w-100" v-if="Object.keys(orders).length > 0">
                <div class="col-md-12 p-0">
                  <div class="cardbox-main">
                    <div class="cardbox">
                      <div class="order-status-bar">
                        <h3 v-if="orders.order_status == 'Placed'">{{ $t('Order Placed') }}</h3>
                        <h3 v-else-if="orders.order_status == 'InKitchen'">{{$t('Order Confirmed')}}</h3>
                        <h3 v-else-if="orders.delivery_pickup_types == 'Delivery' && orders.order_status == 'ReadyToServe'">{{$t('Order Confirmed')}}</h3>
                        <h3 v-else-if="orders.delivery_pickup_types == 'Delivery' && orders.order_status == 'OnTheWay'">{{$t('On The Way')}}</h3>
                        <h3 v-else-if="orders.delivery_pickup_types == 'Takeaway' && orders.order_status == 'ReadyToServe'">{{$t('Order Ready To Pickup')}}</h3>
                        <h3 v-else-if="orders.delivery_pickup_types == 'Delivery' && orders.order_status == 'Arrived'">{{$t('Arrived')}}</h3>
                        <h3 v-else-if="orders.delivery_pickup_types == 'Delivery' && orders.order_status == 'Delivered'">{{$t('Delivered')}}</h3>
                        <h3 v-else-if="orders.delivery_pickup_types == 'Takeaway' && orders.order_status == 'Delivered'">{{$t('Picked Up')}}</h3>
                        <h3 v-else-if="orders.delivery_pickup_types == 'Takeaway' && orders.order_status == 'Cancelled'">{{$t('Order Collected')}}</h3>
                        <h3 v-else-if="orders.order_status == 'Cancelled'">{{$t('Order Cancelled')}}</h3>
                        <h3 v-else>{{$t('Cancelled')}}</h3>
                        <div class="order-stauts-info mt-2">
                          <p>{{ $t('Order') }} <span>#{{ orders.order_id }}</span></p> 
                          <p><span>{{ orders.restaurant_item_details.length }}</span><span v-text="orders.restaurant_item_details.length > 0 && orders.restaurant_item_details.length == 1 ? ' item' : ' items'"></span></p>
                          <p><span v-html="$auth.setting && $auth.setting.currency"></span>{{parseFloat(orders.total_amount || 0).toFixed(2) }}</p>
                        </div>
                      </div>
                      <div class="responsive-map">
                        <!-- <GmapMap v-if="markers.length > 0" :center="center" :zoom="15" style="height: 320px !important">
                          <GmapMarker :key="index" v-for="(m, index) in markers" :position="m" :clickable="true" :draggable="false" :icon="{ url: require('../assets/images/'+m.label)}" />
                          <DirectionsRenderer travelMode="DRIVING" :origin=markers[0] :destination=markers[1] />
                        </GmapMap> -->
                        <GoogleMaps v-if="markers.length > 0" :markers="markers" :center="center" page="orderstatus"></GoogleMaps>
                      </div>
                    </div>
                    <div class="cardbox">
                      <div class="infocard">
                      <div class="infocard__item" v-if="orders.delivery_pickup_types == 'Delivery' && driverName != '' ">
                        <div class="infocard__body d-flex align-items-center">
                          <div class="infocard__img-box me-3">
                            <img :src="driverImage" @error="$event.target.src = noImge"/>
                          </div>
                          <div class="infocard__content">
                            <h6>{{ driverName }}</h6>
                            <p class="orderid" v-if="orders.order_status == 'ReadyToServe'">{{$t('driver is on the way to pickup location',{msg:$auth.setting.driver_name})}}.</p>
                            <p class="orderid" v-else-if="orders.order_status == 'OnTheWay'">{{$t('our driver is out for delivery',{msg:$auth.setting.driver_name})}}.</p>              
                            <p class="orderid" v-else-if="orders.order_status == 'Arrived'">{{$t('Your order has just arrived at your doorstep')}}.</p>
                            <p class="orderid" v-else-if="orders.order_status == 'Delivered'">{{$t('Your Order has been Delivered. Please don\'t forget to rate our service')}}.</p>           
                            <p class="orderid" v-else></p>
                          </div>
                        </div>
                        <div class="infocard__footer d-flex justify-content-center" v-if="orders.order_status != 'Delivered'">
                          <router-link v-if="$auth.setting.is_enable_live_tracking_app == '1' && orders.is_dunzo_driver == '0' && (orders.order_status == 'OnTheWay' || orders.order_status == 'Arrived')" :to="{name:'trackorder', params:{order_id: base64_encode(orders.order_id)}}">{{$t('Track Driver')}}</router-link>
                          <a v-if="orders.order_status != 'Delivered'" href="javascript:void(0)">Call {{ driverName }}</a>
                          <!-- <a v-if="orders.order_status == 'Delivered'" href="#">Rate Us</a> -->
                        </div>
                      </div> 
                      <div class="infocard__item">
                        <div class="infocard__body d-flex align-items-center">
                          <div class="infocard__img-box me-3">
                            <img :src="orders.restaurant_image" @error="$event.target.src = noImge"/>
                          </div>
                          <div class="infocard__content">
                            <h6 class="mb-1">{{ orders.restaurant_name }}</h6>
                            <p class="orderid" v-if="orders.order_status == 'Placed'">{{ $t('We have Received your order Request.Waiting for name to confirm the order',{ msg: orders.restaurant_name }) }}.</p>                        
                            <p class="orderid" v-if="orders.delivery_pickup_types == 'Delivery' && (orders.order_status == 'InKitchen' || orders.order_status == 'ReadyToServe')">{{$t('restaurant_name is processing your order. As soon as it is ready, we will notify you',{msg:orders.restaurant_name})}}.</p>
                            <p class="orderid" v-if="orders.delivery_pickup_types == 'Takeaway' && (orders.order_status == 'InKitchen' || orders.order_status == 'ReadyToServe')">{{$t('your order is ready to pickup. Collect order as soon as possible from name',{msg:orders.restaurant_name})}}.</p>
                            <p class="orderid" v-if="orders.order_status == 'OnTheWay' || orders.order_status == 'Arrived'">{{ $t('your order is handover to driver',{ msg:$auth.setting.driver_name }) }}.</p>                        
                            <p class="orderid" v-if="orders.delivery_pickup_types == 'Delivery' && orders.order_status == 'Delivered'">{{ $t('we hope you enjoy food') }}.</p>
                            <p class="orderid" v-if="orders.delivery_pickup_types == 'Takeaway' && orders.order_status == 'Delivered'">{{ $t('your order has been collected. Please don\'t forget to rate our service') }}.</p>            
                          </div>
                        </div>
                        <div class="infocard__footer d-flex justify-content-center">
                          <router-link :to="{name: 'order-details',params: { order_id: base64_encode(orders.order_id) },}"><span>{{ $t('view order summary') }}</span></router-link>
                          <!-- <a v-if="orders.order_status == 'Delivered'" href="#">Rate Us</a> -->
                          <!-- <a v-else href="#">Support</a> -->
                        </div>
                      </div> 
                      <div class="infocard__item infocard__item--support">
                        <div class="infocard__body d-flex align-items-center">
                          <div class="infocard__img-box me-3">
                            <img src="@/assets/images/support-icon.svg"/>
                          </div>
                          <div class="infocard__content">
                            <h6 class="mb-1">{{$t('Need Help with your order')}}</h6>
                            <p class="orderid">{{$auth.getAppName()}} {{$t('Support is always available')}}</p>
                          </div>
                        </div>
                        <div class="infocard__footer d-flex justify-content-center">
                          <router-link to="/support-chat" v-if="$auth.user && $auth.setting && $auth.setting.client_chat_status == 'active'" >{{$t('Chat with Us')}}</router-link>
                          <router-link to="/support">{{$t('Support')}}</router-link>
                        </div>
                      </div> 
                  </div>
                    </div>
                  </div>
                  <div v-if="orders.order_status == 'Delivered'" class="my-3">
                    <div class="deliveryaddress plr15 rate-btn" v-if="ratingExiting == 'false'">
                      <div class="full-btn">
                        <router-link :to="{name:'rating', params:{order_id: 
                          base64_encode(orders.order_id)}}">{{$t('Rate Us')}}</router-link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="order-offers" v-if="Object.keys(orders).length == 0 && !loading">
        <p class="text-center">{{ $t('No Order Found') }}</p>
      </div>      
    </section>
    <Footer></Footer>
  </div>
</template>

<script>
import NavBar from "@/components/NavBar.vue";
import { mapActions } from "vuex";
import Footer from '../components/Footer.vue';
import DirectionsRenderer from "../components/DirectionsRenderer.vue";
import noImge from "@/assets/images/place_holder.png";
import config from './../config/index';
import GoogleMaps from "../components/GoogleMaps.vue";

export default {
  components: {NavBar,Footer,DirectionsRenderer,GoogleMaps},
  data() {
    return {
      noImge,
      orders:{},
      zoom: 12,
      center: {
        lat: '',
        lng: '',
      },
      markers : [],
      mapOptions: { disableDefaultUI: true},      
      ratingExiting: '',
      loading: true,
      driverImage:'',
      driverName:'',
      loadShipmentStatus:'',      
    };
  },
  mounted() {
    setTimeout(() => {
      if(this.$auth.user){
        this.checkRating();
        this.orderDetails();
      }else{
        this.$router.push({ name: "sign-in" });
      }
    }, 1000);
  },

  methods: {
    ...mapActions("order", ["viewOrderDetail", "checkOrderRating"]),
    
    orderDetails() {
      if(this.isBase64(this.$route.params.order_id)) {
        this.viewOrderDetail({
          vendor_id: this.$auth.getVendorId(),
          order_id: this.base64_decode(this.$route.params.order_id),
          is_langauge: this.$store.state.lang.locale,
        }).then((data) => {
          if (data.code == 200) {
            this.orders = data.Result[0];
            this.center.lat = parseFloat(this.orders.restaurant_latitude);
            this.center.lng = parseFloat(this.orders.restaurant_longitude);
            this.loading = false;
            if(data.Result && data.Result[0].order_status == 'Cancelled'){
              this.$router.push({ name: "order-details", params: { order_id: this.base64_encode(data.Result[0].order_id) }})
            }
            /**
            * If Load Sharing Driver assign
            */
           let driverLat = this.orders.delivery_latitude
           let driverLong = this.orders.delivery_longitude
           this.driverImage = this.orders.driver_image;
          if(this.orders.is_load_sharing_driver == '1'){
            this.driverName = this.orders.loadsharingdriver_details.name;
            driverLat = this.orders.loadsharingdriver_details.location.latitude;
            driverLong = this.orders.loadsharingdriver_details.location.longitude;                        
            let loadSharingToken = this.$auth.setting.load_sharing_token;
            let loadSharingCustomerCode = this.$auth.setting.load_sharing_customer_code;
            var interval = setInterval(() => {
              window.axios.get(config.loadSharAPI+'order/'+this.orders.order_id+'/track', {
                headers: {
                  'Checksum' : 'SHA256('+loadSharingToken+'|'+loadSharingCustomerCode+'|'+this.orders.order_id+')',
                  'Customer-Code':loadSharingCustomerCode,
                }
              }).then((response) => {
                this.loadShipmentStatus = response.shipmentStatus;
                this.markers[0].lat = response.deDetails.location.latitude;
                this.markers[0].lng = response.deDetails.location.longitude;
                this.driverName = response.deDetails.name;                
                if(this.loadShipmentStatus != 'DELIVERED' || this.$route.name != 'order-status'){
                  clearInterval(interval);
                }
              }).catch((error) => {
                clearInterval(interval);
              });
              
            }, 5000);            
          }
          this.markers = [
            {
              lat: parseFloat(driverLat),
              lng: parseFloat(driverLong),
              label: 'icon_delivery_marker.png'
            },
            {
              lat: parseFloat(this.orders.restaurant_latitude),
              lng: parseFloat(this.orders.restaurant_longitude),
              label: 'icon_store_marker.png'
            },
          ];
          }          
        });
      } else {
        this.orders = {};
        this.loading = false;
      }
    },

    checkRating() {
      this.checkOrderRating({
        vendor_id: this.$auth.getVendorId(),
        order_id: this.base64_decode(this.$route.params.order_id),
        is_langauge: this.$store.state.lang.locale,
      }).then((data) => {
        if (data.code == 200) {
          this.ratingExiting = data.Result.status;
        }
      });
    },
    
    isBase64(str) {
      if (str ==='' || str.trim() ===''){ return false; }
      try {
        return btoa(atob(str)) == str;
        } catch (err) {
          return false;
        }
    },

    handleMapClick(e) {
      this.marker.position = { lat: e.latLng.lat(), lng: e.latLng.lng() };
      this.locateAddress(e.latLng.lat(), e.latLng.lng());
    },
    
  },
};
</script>
